import Vue from 'vue';
import Component from 'vue-class-component';
import { DUNATI_DOMAIN, DUNATI_ORGANIZATION_SCHEMA, ROBOT_CONTENT } from '@/main';
import { ApiServices } from '@/services/api.services';
import { Post } from '@/class/post';
// @ts-ignore
import Media from '@dongido/vue-viaudio';
import { Category } from '@/class/category';
import CardPostRelatedComponent from '@/components/card-post-related/card-post-related.component';

const components: any = {
  CardPostRelatedComponent
};

if (typeof window !== 'undefined') {
  components.Media = Media;
}

@Component({
  components
})
export default class BlogDetailPage extends Vue {
  public blog: any = null;
  public loadInit: boolean = false;

  public related: Post[] = [];

  constructor() {
    super();
  }

  public mounted() {
    this.loadInit = true;
    this.getInitData();
    this.$store.dispatch('changeMenu', 'bg1');
  }

  public destroyed() {
    this.$store.dispatch('changeMenu', 'normal');
  }

  public getInitData(s: boolean = false) {
    return new Promise((resolve, reject) => {
      this.loadInit = true;
      const slug = this.$route.params.slug;

      ApiServices.getPostsBySlug(slug).then((response) => {
        if (response.data) {
          this.blog = new Post(response.data.data);

          this.getRelated();

          this.loadInit = false;
          this.updateTags();
          this.updateJsonld();
          setTimeout(() => {
            return resolve(true);
          }, 10);
        } else {
          this.$router.push('/404');
        }
      }).catch((error) => {
        console.log(error);
        if (s) {
          return reject({ redirectTo: '/blog', error: error });
        } else {
          this.$router.push('/404');
        }
      });
    });
  }

  public getRelated() {
    if (!this.blog) return;

    const categories = this.blog.categories.map((x: Category) => x.id);
    
    ApiServices.getPosts(`where[categories]=${categories}&where[id][ne]=${this.blog.id}&limit=3`).then((pResponse) => {
      if (pResponse.data) {
        this.related = pResponse.data.data;
      }
    })
  }

  public serverPrefetch() {
    this.loadInit = false;
    return this.getInitData(true);
  }

  public toRoute(router: string) {
    this.$router.push({ path: router });
  }

  public getAlt(item: any): string {
    if (item && item.alternativeText !== "") {
      return item.alternativeText;
    } else if (item && item.name) {
      const cover = (item.name + '').split('/');
      const img = cover[cover.length - 1].split('.')[0];
      return `${img.replace(/-/g, ' ')}`;
    } else {
      return '';
    }
  }

  public getTitle(item: any): string {
    if (item && item.caption !== "") {
      return item.caption;
    } else if (item && item.name) {
      const cover = (item.name + '').split('/');
      const img = cover[cover.length - 1].split('.')[0];
      return `${img.replace(/-/g, ' ')}`;
    } else {
      return '';
    }
  }

  public getImage(img: any): string {
    if (img) {
      return ApiServices.strapiUrl + img.url;
    } else {
      return '';
    }
  }

  public isImage(mime: string): boolean {
    var types = [
      'image/ief',
      'image/jpeg',
      'image/png',
      'image/pipeg',
      'image/svg+xml',
      'image/svg',
      'image/tiff',
      'image/x-cmu-raster',
      'image/x-cmx',
      'image/x-icon',
      'image/x-portable-anymap',
      'image/x-portable-bitmap',
      'image/x-portable-graymap',
      'image/x-portable-pixmap',
      'image/x-rgb',
      'image/x-xbitmap',
      'image/x-xpixmap',
      'image/x-xwindowdump',
      'image/webp'
    ];
    return types.indexOf(mime) > -1 ? true : false;
  }

  public updateTags() {
    this.$options.dunatiHeadTags = {
      title: `${this.blog.title} - Escritorios y Sillas de Oficina | Dunati`,
      metaTags: [
        // Primaty Tags /
        {
          name: 'description',
          content: this.blog.summary,
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        // Open Graph / Facebook /
        {
          property: 'og:type',
          content: 'article',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/blog/${this.blog.slug}`,
        },
        {
          property: 'og:title',
          content: `${this.blog.title} - Escritorios y Sillas de Oficina | Dunati`,
        },
        {
          property: 'og:description',
          content: this.blog.summary,
        },
        {
          property: 'og:image',
          content: this.getImage(this.blog.cover),
        },
        // Twitter /
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/blog/${this.blog.slug}`,
        },
      ],
    };
    // @ts-ignore
    this.renderTags();
  }

  private updateJsonld() {
    this.$options.dunatiJsonld = [
      DUNATI_ORGANIZATION_SCHEMA,
      {
        "@context":"http://schema.org",
        "@type": "BlogPosting",
        "image": this.getImage(this.blog.cover),
        "url": `${DUNATI_DOMAIN}/blog/${this.blog.slug}`,
        "headline": this.blog.title,
        "alternativeHeadline": this.blog.summary,
        "dateCreated": this.blog.createdAt,
        "datePublished": this.blog.post_data,
        "dateModified": this.blog.updatedAt,
        "inLanguage": "es",
        "isFamilyFriendly": "true",
        "copyrightYear": "2020",
        "copyrightHolder": "",
        "author": {
          "@type": "Person",
          "name": "Leonardo Bianchi",
          "url": `${DUNATI_DOMAIN}`
        },
        "creator": {
          "@type": "Person",
          "name": "Leonardo Bianchi",
          "url": `${DUNATI_DOMAIN}`
        },
        "publisher": {
          "@type": "Organization",
          "name": "Dunati",
          "url": `${DUNATI_DOMAIN}`,
          "logo": {
            "@type": "ImageObject",
            "@id": `${DUNATI_DOMAIN}/#logo`,
            "url": `${DUNATI_DOMAIN}/assets/images/logo_oscuro.svg`,
            "width": 400,
            "height": 200,
            "caption": "Dunati"
          }
        },
        "keywords": [],
        "genre": this.blog.categories.map((x: Category) => x.name)
      }
    ]

    // @ts-ignore
    this.renderJsonld();
  }
}
