import { Article } from './article';
import { Category } from './category';
import { Section } from './section';

export class Post {
  public id!: string;
  public title!: string;
  public summary!: string;
  public slug!: string;
  public rate!: number;
  public cover!: string;
  public post_data!: string;
  public status!: string;

  // public articles: Article[] = []; 
  public sections: Section[] = [];
  public categories: Category[] = [];

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.title = data.title;
      this.summary = data.summary;
      this.slug = data.slug;
      this.rate = +data.rate;
      this.cover = data.cover;
      this.post_data = data.post_data;
      this.status = data.status;

      if (data.categories) {
        data.categories.forEach((element: any) => {
          this.categories.push(new Category(element));
        });
      }

      // if (data.articles) {
      //   data.articles.forEach((element: Article) => {
      //     this.articles.push(new Article(element));
      //   });
      // }

      if (data.sections) {
        data.sections.forEach((element: Section) => {
          this.sections.push(new Section(element));
        });
      }

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
