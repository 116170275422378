import { StrapiFile } from "./strapiFile";

export class Section {
  public id!: number;
  public img!: string;
  public layout!: string;
  public subtitle!: string;
  public text!: string;
  public videoControls!: boolean;
  public videoLoop!: boolean;
  public videoAutoplay!: boolean;
  public videoMuted!: boolean;
  public videoEmbed!: string;

  // file
  public videoThumbnail!: StrapiFile;

  public updatedAt!: string;
  public createdAt!: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.img = data.img;
      this.layout = data.layout.toLowerCase();
      this.subtitle = data.subtitle;
      this.text = data.text;
      this.videoControls = data.video_controls;
      this.videoLoop = data.video_loop;
      this.videoAutoplay = data.video_autoplay;
      this.videoMuted = data.video_muted;
      this.videoEmbed = data.video_embed;

      if (data.video_thumbnail) {
        this.videoThumbnail = new StrapiFile(data.video_thumbnail);
      }

      this.updatedAt = data.updatedAt;
      this.createdAt = data.createdAt;
    }
  }
}
