import { ApiServices } from '@/services/api.services';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class CardPostRelatedComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public data: any;

  public mounted() {
  }

  public details(slug: string) {
    this.$router.push({ name: 'blogs-detail', params: { slug } });
  }

  public getAlt(): string {
    if (this.data.cover && this.data.cover.alternativeText !== "") {
      return this.data.cover.alternativeText;
    } else if(this.data.cover && this.data.cover.name) {
      const cover = (this.data.cover.name + '').split('/');
      const img = cover[cover.length - 1].split('.')[0];
      return `${img.replace(/-/g, ' ')}`;
    } else {
      return '';
    }
  }

  public getTitle(): string {
    if (this.data.cover && this.data.cover.caption !== "") {
      return this.data.cover.caption;
    } else if(this.data.cover && this.data.cover.name) {
      const cover = (this.data.cover.name + '').split('/');
      const img = cover[cover.length - 1].split('.')[0];
      return `${img.replace(/-/g, ' ')}`;
    } else {
      return '';
    }
  }

  public getImage(): string {
    if (this.data.cover) {
      return ApiServices.strapiUrl + this.data.cover.url;
    } else {
      return '';
    }
  }
}

