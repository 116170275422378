import Vue from 'vue';
import Component from 'vue-class-component';
import CardPostComponent from '@/components/card-post/card-post.component';
import { DUNATI_DOMAIN, DUNATI_ORGANIZATION_SCHEMA, ROBOT_CONTENT } from '@/main';
import { ApiServices } from '@/services/api.services';
import { Post } from '@/class/post';
import { Category } from '@/class/category';
import DunatiLoaderSimpleComponent from '@/components/dunati-loader-simple/dunati-loader-simple.component';

@Component({
  components: {
    CardPostComponent,
    DunatiLoaderSimpleComponent
  },
})
export default class BlogComponent extends Vue {

  public loading: boolean = false;
  public categories: string[] = [];
  public listBlogs: Post[] = [];
  public tokeepBlogs: Post[] = [];
  public listPlaceholders = Array(3).fill(1);
  public current: any = null;

  public itemsPeerPage = 6; // cantidad de blogs a mostrar
  public itemsPeerPageKeep = 6; // para resetear al anterior

  constructor() {
    super();
  }

  public mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.getInitData();
  }

  public destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  public handleScroll(event: Event) {
    if (typeof window !== 'undefined' && !this.loading) {
      const div = document.querySelector('.cnt-list-post');
      const st = window.scrollY || document.documentElement.scrollTop;

      if(div && st >= (div.scrollHeight - (div.scrollHeight * 0.20))) {
        this.loadMore();
      }
    }
  }

  public getInitData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loading = true;

      ApiServices.getPostCategories().then((cResponse) => {
        if (cResponse.data) {
          this.categories = cResponse.data.data.map((x: Category) => x.name.toLowerCase());
        }

        ApiServices.getPosts().then((pResponse) => {
          if (pResponse.data) {
            const posts = pResponse.data.data;
            posts.forEach((element: Post) => {
              this.listBlogs.push(element);
            });

            this.listBlogs = this.listBlogs.sort((a: Post, b: Post) => b.rate - a.rate);
            this.tokeepBlogs = this.listBlogs;
          }

          this.updateTags();
          this.updateJsonld();
          setTimeout(() => {
            this.loading = false;
            this.listPlaceholders = [];
            return resolve();
          }, 1);
        });
      }).catch(error => {
        console.log(error);
        return reject();
      })
    });
  }

  public serverPrefetch() {
    return this.getInitData();
  }

  public showCategory(category: string) {
    if (category && category !== 'otros') {
      this.current = category;
      const check = (obj: any) => obj.name.toLowerCase() === category;
      this.listBlogs = this.tokeepBlogs.filter((x: any) => x.categories.some(check));
    } else if (category === 'otros') {
      this.current = category;
      const list = this.tokeepBlogs;

      for (let i = 0; i < this.categories.length; i++) {
        const element = this.categories[i];
        if (element !== 'otros') {
          const check = (obj: any) => obj.name.toLowerCase() === element;
          this.listBlogs = this.tokeepBlogs.filter((x: any) => x.categories.some(check));
        }
      }

      this.listBlogs = list;
    } else {
      this.current = null;
      this.listBlogs = this.tokeepBlogs;
    }
  }

  public loadMore() {
    if(this.itemsPeerPage > this.listBlogs.length) return;

    let oldPosition: number = window.scrollY;
    this.itemsPeerPage += this.itemsPeerPageKeep;

    this.$nextTick(() => {
      window.scrollTo(0, oldPosition);
    });
  }

  public updateTags() {
    this.$options.dunatiHeadTags = {
      title: 'Blog - Escritorios y Sillas de Oficina | Dunati',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: 'Artículos relacionados con el diseño, bienestar e innovación en los espacios de trabajo. Cada solución de diseño real comienza con la investigación y está centrada en las personas.',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        /* Open Graph / Facebook */
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/blog`,
        },
        {
          property: 'og:title',
          content: 'Blog - Escritorios y Sillas de Oficina | Dunati',
        },
        {
          property: 'og:description',
          content: 'Artículos relacionados con el diseño, bienestar e innovación en los espacios de trabajo. Cada solución de diseño real comienza con la investigación y está centrado en las personas.',
        },
        {
          property: 'og:image',
          content: `${DUNATI_DOMAIN}/assets/images/home_page/og_home.jpg`,
        },
        /* Twitter */
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/blog`,
        },
      ],
      /*
      metaInfo: {
        link: [
          {
            rel: 'canonical',
            href: `${DUNATI_DOMAIN}/blog`
          }
        ]
      }
      */
    };

    // @ts-ignore
    this.renderTags();
  }

  private updateJsonld() {
    this.$options.dunatiJsonld = [
      DUNATI_ORGANIZATION_SCHEMA,
      {
        "@context": "https://schema.org",
        "@type": "Blog",
        "url": `${DUNATI_DOMAIN}/blog`,
        "headline": 'Blog - Muebles de oficina | Dunati',
        "description": "Defendiendo los intereses misioneros",
        "blogPost": this.getPosts()
      }
    ]

    // @ts-ignore
    this.renderJsonld();
  }

  private getPosts(): object[] {
    let listPost: object[] = [];

    for (let i = 0; i < this.tokeepBlogs.length; i++) {
      const element = this.tokeepBlogs[i];

      listPost.push({
        "@type": "BlogPosting",
        "image": this.getImage(element.cover),
        "url": `${DUNATI_DOMAIN}/blog/${element.slug}`,
        "headline": element.title,
        "alternativeHeadline": element.summary,
        "dateCreated": element.createdAt,
        "datePublished": element.post_data,
        "dateModified": element.updatedAt,
        "inLanguage": "es",
        "isFamilyFriendly": "true",
        "copyrightYear": "2020",
        "copyrightHolder": "",
        "author": {
          "@type": "Person",
          "name": "Leonardo Bianchi",
          "url": `${DUNATI_DOMAIN}`
        },
        "creator": {
          "@type": "Person",
          "name": "Leonardo Bianchi",
          "url": `${DUNATI_DOMAIN}`
        },
        "publisher": {
          "@type": "Organization",
          "name": "Dunati",
          "url": `${DUNATI_DOMAIN}`,
          "logo": {
            "@type": "ImageObject",
            "@id": `${DUNATI_DOMAIN}/#logo`,
            "url": `${DUNATI_DOMAIN}/assets/images/logo_oscuro.svg`,
            "width": 400,
            "height": 200,
            "caption": "Dunati"
          }
        },
        "keywords": [],
        "genre": element.categories.map((x: Category) => x.name)
      });
    }

    return listPost;
  }

  public getImage(img: any): string {
    if (img) {
      return ApiServices.strapiUrl + img.url;
    } else {
      return '';
    }
  }
}

